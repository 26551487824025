var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.chunkedImages && _vm.chunkedImages.vertical)?_vm._l((_vm.chunkedImages.vertical),function(arrImages,idx){return _c('div',{key:idx,staticClass:"grid-container"},[_c('div',{staticClass:"grid-type grid-type--oneline",class:{
          'grid-type--oneline-1': arrImages.length === 1,
          'grid-type--oneline-2': arrImages.length === 2,
          'grid-type--oneline-3': arrImages.length === 3
        }},_vm._l((arrImages),function(image,index){return _c('figure',{key:index,staticClass:"grid__item"},[(_vm.isManage)?_c('ul',{staticClass:"dashboard__list"},[_c('li',[_c('span',{staticClass:"dashboard__badge badge-green m0"},[_vm._v(" id: "+_vm._s(image.id)+" ")])]),_c('li',[_c('button',{attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.remove(image.id)}}},[_vm._v(" Remove ")])]),_c('li',[_c('button',{attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.edit(image.id)}}},[_vm._v(" Edit ")])])]):_vm._e(),(_vm.isShots)?_c('button',{staticClass:"grid__btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.goTo(image)}}},[_vm._v(" MORE ")]):_vm._e(),_c('a',{ref:"lightbox",refInFor:true,staticClass:"grid__lightbox",attrs:{"href":image.src}},[_c('img',{staticClass:"grid__img",attrs:{"src":image.src,"alt":"","loading":"lazy"}})])])}),0)])}):_vm._e(),(_vm.chunkedImages && _vm.chunkedImages.horizontal)?_vm._l((_vm.chunkedImages.horizontal),function(arrImages,idx){return _c('div',{key:("0" + idx),staticClass:"grid-container"},[_c('div',{staticClass:"grid-type",class:{
          'grid-type--big-on-left': arrImages.length === 3 && (idx + 1) % 2,
          'grid-type--big-on-right':
            arrImages.length === 3 && !((idx + 1) % 2),
          'grid-type--two': arrImages.length === 2,
          'grid-type--one': arrImages.length === 1
        }},_vm._l((arrImages),function(image,index){return _c('figure',{key:index,staticClass:"grid__item"},[(_vm.isManage)?_c('ul',{staticClass:"dashboard__list",attrs:{"slot":"default"},slot:"default"},[_c('li',[_c('span',{staticClass:"dashboard__badge badge-green m0"},[_vm._v(" id: "+_vm._s(image.id)+" ")])]),_c('li',[_c('button',{attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.remove(image.id)}}},[_vm._v(" Remove ")])]),_c('li',[_c('button',{attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.edit(image.id)}}},[_vm._v(" Edit ")])])]):_vm._e(),(_vm.isShots)?_c('button',{staticClass:"grid__btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.goTo(image)}}},[_vm._v(" MORE ")]):_vm._e(),_c('a',{ref:"lightbox",refInFor:true,staticClass:"grid__lightbox",attrs:{"href":image.src}},[_c('img',{staticClass:"grid__img",attrs:{"src":image.src,"alt":"","loading":"lazy"}})])])}),0)])}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }